//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.btn {
    text-transform: uppercase;
}

.navbar {
    font-family: "Montserrat", sans-serif;
}

.pricing .dropdown {

    .btn {
        font-size: 1.25rem;
        text-transform: none;
    }

    .dropdown-item {
        font-size: 1.25rem;
    }
}
